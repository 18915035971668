import React, { useEffect, useState } from "react";
import { ReactComponent as PressIcon } from './../assets/icons/press.svg';
import { ReactComponent as ArticleIcon } from './../assets/icons/article.svg';
import { ReactComponent as PosterIcon } from './../assets/icons/poster.svg';
import { ReactComponent as EventIcon } from './../assets/icons/event.svg';
import { ReactComponent as PresentationIcon } from './../assets/icons/presentation.svg';
import styles from "./../style/home.module.css";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {

  const videoSource = require("./../assets/video/bg.mov");
  const [news, newsSet] = useState([]);
  const navigate = useNavigate();

  const handleLinkClick = (path: string): React.MouseEventHandler<HTMLDivElement> => () => {
    navigate(path);
  };

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/media/news.json')
      .then(response => response.json())
      .then(data => newsSet(data));
  }, [])

  return (
    <div className={styles.homeContent}>
      <div className={styles.homeMessageContainer}>
        <div className={styles.homeMessageOverlay}></div>
        <div className={styles.videoBg}>
          <video className={styles.video} autoPlay loop muted playsInline preload="auto">
            <source src={videoSource} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.homeMessage}>
          <div className={styles.homeMessageText}><span className={styles.homeMessageTextReegineering}>Re-engineering biology</span><br />enabling the next generation of cell and gene therapies</div>
        </div>
      </div>
      <div className={styles.homeTechSection}>
        <div className={styles.homeTechPipeHeadline}>Antion has developed a propietary gene engineering technology with unmatched versatility to overcome key challenges facing cell and gene therapy</div>
        <div className={styles.homeTechPipeButtonSection}>
          <div className={styles.homeTechPipeButtons}>
            <div className={styles.homeTechPipeButton} onClick={handleLinkClick("bd")}>
              <Link to="technology">
                <div className={styles.homeTechPipeButtonContainer}>
                  <div className={styles.homeTechPipeButtonText}>Our multiplex engineering platform enables scalable manufacturing of cell and gene therapy products with unprecedented safety and efficiency.</div>
                  <div className={styles.homeTechPipeButtonLink}>EXPLORE OUR TECHNOLOGY &#8594;</div>
                </div>
              </Link></div>
            {/* <div className={styles.homeTechPipeButton} onClick={handleLinkClick("pipeline")}>
              <Link to="pipeline">
                <div className={styles.homeTechPipeButtonContainer}>
                  <div className={styles.homeTechPipeButtonText}>We are developing next generation products to address significant unmet clinical needs and to broaden access of life-changing therapies.</div>
                  <div className={styles.homeTechPipeButtonLink}>SEE OUR PIPELINE &#8594;</div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <div className={styles.latestNews}>
        <div className={styles.latestNewsSection}>
          <div className={styles.latestNewsSectionTitle}>News highlights</div>
          <div className={styles.latestNewsList}>
            <ul className={styles.latestNewsListUl}>
              {
                news.map((item: any, index: number) => (
                  item.includeLatest &&
                  <li className={styles.latestNewsItem} key={index} >
                    {item.type === "press" && <PressIcon className={styles.latestNewsIcon} />}
                    {item.type === "article" && <ArticleIcon className={styles.latestNewsIcon} />}
                    {item.type === "poster" && <PosterIcon className={styles.latestNewsIcon} />}
                    {item.type === "event" && <EventIcon className={styles.latestNewsIcon} />}
                    {item.type === "presentation" && <PresentationIcon className={styles.latestNewsIcon} />}
                    <div>
                      <div className={styles.latestNewsDate}>{item.dateOut}</div>
                      <div className={styles.latestNewsInfo}>
                        {item.link ?
                          <div className={styles.latestNewsTitle}><a href={item.link} target="_blank" rel="noreferrer">{item.title}</a></div>
                          :
                          <div className={styles.latestNewsTitle}>{item.title}</div>}
                        {item.description && <div className={styles.latestNewsDescription}>{item.description}</div>}
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div >
      </div >
    </div >
  )
}

export default Home