import { useEffect, useState } from "react";
import autologous from './../assets/img/autologous.svg';
import allogeneic from './../assets/img/allogeneic.svg';
import micell from './../assets/img/miCell.svg';
import SafetyIcon from "../assets/img/SafetyIcon";
import EfficacyIcon from "../assets/img/EfficacyIcon";
import FeasibilityIcon from "../assets/img/FeasibilityIcon";
import FlexibilityIcon from "../assets/img/FlexibilityIcon";
import TunabilityIcon from "../assets/img/TunabilityIcon";
import { ReactComponent as ArticleIcon } from './../assets/icons/article.svg';
import { ReactComponent as PosterIcon } from './../assets/icons/poster.svg'

import styles from "./../style/technology.module.css";
import { useLocation } from "react-router-dom";

const BusinessDevelopment = () => {


  const [publications, publicationSet] = useState([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/publications/publications.json')
      .then(response => response.json())
      .then(data => publicationSet(data));
  }, [])

  const location = useLocation();

  useEffect(() => {
    // Check if there is a hash in the URL
    if (location.hash) {
      const id = location.hash.replace("#", ""); // Remove the '#' from the hash
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      // Optionally, scroll to the top if there's no hash
      window.scrollTo(0, 0);
    }
  }, [location]); // Re-run the effect if the location changes

  return (
    <div className={styles.technologyCcontent}>
      {/* MULTIPLEXING */}
      <div id="multiplex" className={styles.technologySectionMultiplex}>
        <div className={`${styles.section} ${styles.multiplexSection}`}>
          <div className={styles.multiplexTextContent}>
            <div className={`${styles.technologySectionTitle} ${styles.technologySectionTitleRevColor}`}>Multiplex engineering platform</div>
            <div className={styles.multiplexText}>Antion's proprietary multiplex engineering platform is modular by design and exceptionally versatile. While most developers are able to efficiently deliver one or more genes to target cells, few are able to simultaneously remove the expression of other genes. Antion’s platform enables a seamless integration of both the co-expression and silencing of multiple genes from a single gene construct. Since this approach relies on single gene delivery step, we are able to manufacture multiplex engineered cells with unprecedented safety and efficiency when compared to other top-tier genome engineering technologies. Beyond this multiplex capability, our technology also offers the unique feature of being able to tune-down expression of molecules to desired levels. In certain cases, we have demonstrated that functional silencing to within a specified range, rather than complete knockout of a gene, can be more beneficial to the performance of therapeutic cells.</div>
          </div>
        </div>
        <div className={styles.theFuture}>
          <div className={styles.theFutureContent}>
            <div className={styles.theFutureFirstLine}>
              <div className={styles.theFutureDescription}>
                <div className={styles.theFutureDescriptionHeadline}>Antion's technology unlocks the full potential of next generation cell and gene therapies</div>
              </div>
              <img src={micell} className={styles.micellimg} alt="Antion's engineered cell" />
              <div className={styles.theFutureDescription}>
                <div className={styles.theFutureDescriptionLowerPoints}>
                  <div className={styles.theFutureDescriptionLowerPointHeadline}>We engineer therapeutic cells with multimodal capability, unprecedented safety and efficiency</div>
                </div>
              </div>
            </div>
            <div className={styles.theFutureSecondLine}>
              {/* SAFETY */}
              <div className={styles.theFuturePoint}>
                <div className={styles.futureIcon}>
                  <SafetyIcon width="48" height="48" color="rgba(255, 255, 255, 1)" />
                </div>
                <div className={styles.theFuturePointText}>
                  <div className={styles.theFuturePointHeader}>Safety</div>
                  <div className={styles.theFuturePointBullets}>
                    limited risk of genomic instability, off-target effects and adverse immune responses
                  </div>
                </div>
              </div>
              {/* EFFICACY */}
              <div className={styles.theFuturePoint}>
                <div className={styles.futureIcon}>
                  <EfficacyIcon width="48" height="48" color="rgba(255, 255, 255, 1)" />
                </div>
                <div className={styles.theFuturePointText}>
                  <div className={styles.theFuturePointHeader}>Efficacy</div>
                  <div className={styles.theFuturePointBullets}>
                    multiplex engineering enables development of next generation cell and gene therapies
                  </div>
                </div>
              </div>
              {/* FEASIBILITY */}
              <div className={styles.theFuturePoint}>
                <div className={styles.futureIcon}>
                  <FeasibilityIcon width="48" height="48" color="rgba(255, 255, 255, 1)" />
                </div>
                <div className={styles.theFuturePointText}>
                  <div className={styles.theFuturePointHeader}>Feasibility</div>
                  <div className={styles.theFuturePointBullets}>
                    substantially decreased Cost of Goods through off-the-shelf treatments and unprecedented efficiencies
                  </div>
                </div>
              </div>
              {/* FLEXIBILIGY */}
              <div className={styles.theFuturePoint}>
                <div className={styles.futureIcon}>
                  <FlexibilityIcon width="48" height="48" color="rgba(255, 255, 255, 1)" />
                </div>
                <div className={styles.theFuturePointText}>
                  <div className={styles.theFuturePointHeader}>Flexibility</div>
                  <div className={styles.theFuturePointBullets}>
                    plug-and-play modular components, any cell type, any gene delivery method
                  </div>
                </div>
              </div>
              {/* TUNABILITY */}
              <div className={styles.theFuturePoint}>
                <div className={styles.futureIcon}>
                  <TunabilityIcon width="48" height="48" color="rgba(255, 255, 255, 1)" />
                </div>
                <div className={styles.theFuturePointText}>
                  <div className={styles.theFuturePointHeader}>Tunability</div>
                  <div className={styles.theFuturePointBullets}>
                    gene silencing over a dynamic range of gene expression for optimal therapeutic effect
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* BUSINESS DEVELOPMENT */}
      <div id="opportunities" className={styles.technologySectionAllo}>
        <div className={`${styles.section} ${styles.alloSection}`}>
          <div className={styles.promiseOfAlloText}>
            <div className={styles.promiseOfAlloIntro}>
              Antion's proprietary multiplex engineering platform is modular by design and exceptionally versatile. While most developers are able to efficiently deliver one or more genes to target cells, few are able to simultaneously remove the expression of other genes. Antion’s platform enables a seamless integration of both the co-expression and silencing of multiple genes from a single gene construct. Since this approach relies on single gene delivery step, we are able to manufacture multiplex engineered cells with unprecedented safety and efficiency when compared to other top-tier genome engineering technologies. Beyond this multiplex capability, our technology also offers the unique feature of being able to tune-down expression of molecules to desired levels. In certain cases, we have demonstrated that functional silencing to within a specified range, rather than complete knockout of a gene, can be more beneficial to the performance of therapeutic cells.
            </div>
            <div className={styles.promiseOfAlloIntro}>
              <b>Antion’s business model is to leverage its proprietary technology platform through key strategic partnerships and customized development services. Our main activities include: </b>
              <ol>
                <li>out-licensing of our technology and optimized gene constructs</li>
                <li>fee-for-service engineering and development of customized gene constructs</li>
                <li>establishing key strategic partnerships with other technology enablers</li>
                <li>advancing our technology platform and portfolio of assets (R&D)</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div id="publications" className={styles.technologySectionPublications}>
        <div className={`${styles.section} ${styles.publicationsSection}`}>
          <div className={`${styles.technologySectionTitle} ${styles.technologyTitle}`} style={{ height: 'fit-content' }}>Scientific publications</div>
          <div className={styles.publicationsContent}>
            <ul className={styles.publicationsList}>
              {
                publications.map((item: any, index: number) => (
                  <li className={styles.publicationItem} key={index}>
                    {item.type === "article" && <ArticleIcon className={styles.publicationIcon} />}
                    {item.type === "poster" && <PosterIcon className={styles.publicationIcon} />}
                    <div>
                      <div className={styles.publicationDate}>{item.dateOut}</div>
                      <div className={styles.publicationInfo}>
                        <div className={styles.publicationTitle}><a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a></div>
                        <div className={styles.publicationAuthors}>{item.authors.join(", ")}</div>
                      </div>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </div >
  )
}

export default BusinessDevelopment